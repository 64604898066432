<div class="agenda-banner">
    <h1 class="agenda-banner__title">
        Consulte nossa agenda tributária
    </h1>
    <img src="assets/banner-agenda.jpg" alt="Banner Agenda">
</div>

<section class="agenda calendar">
    <div class="container">
        <div class="title title-default">
            <h2 class="title__name">
                <strong>Calendário</strong>
            </h2>
        </div>
        <div class="calendar-filter">
            <div class="filters">
                <h3 class="filters__title">Filtros:&nbsp;&nbsp;</h3>
                <div class="filters-list">
                    <div class="filters-item" *ngFor="let f of filtros; let i = index;">
                        <input type="checkbox" [(ngModel)]="f.checked" (ngModelChange)="calendarFilterChange($event, i)"
                            [id]="f.id" [value]="f.nome" [name]="f.nome">
                        <label [for]="f.id">{{f.nome}}</label>
                    </div>
                </div>
                <button class="btn btn-default btn-small btn-block hidden-desktop"
                    (click)="openFilter = !openFilter">mostrar
                    {{openFilter ? 'menos' : 'mais'}}</button>
            </div>
            <a *ngIf="getCalendarFilteredItens() !== '%'" (click)="cleanFilters()" class="calendar-filter-clean">Limpar
                filtros</a>
        </div>

        <div class="simple-calendar" [style.min-height.px]="simpleCalendarHeight">
            <div class="inner">
                <ngx-simple-calendar [options]="calendarOptions" [events]="calendarEvents" #simpleCalendar
                    id="simpleCalendar">

                    <ng-template #topBar let-month="month" let-year="year">
                        <div class="simple-calendar-header">
                            <button (click)="previousMonth()" class="arrow">
                                <i class="fas fa-chevron-left"></i>
                            </button>
                            <div class="content">
                                <span class="content-month">{{months[month.id]}}</span>
                                <span class="content-year">{{year}}</span>
                            </div>
                            <button (click)="nextMonth()" class="arrow">
                                <i class="fas fa-chevron-right"></i>
                            </button>
                        </div>
                    </ng-template>

                    <ng-template #weekDays let-id="id">
                        <span class="simple-calendar__day hidden-mobile">{{days[id]}}</span>
                        <span class="simple-calendar__day hidden-desktop">{{days[id].substr(0, 3)}}</span>
                    </ng-template>

                    <ng-template #day let-date="number" let-events="events" let-active="today" let-weekend="weekend">

                        <div class="round-date" [class.active]="active" [class.weekend]="weekend"
                            [class.has-events]="events.length">
                            <span class="round-date__number">{{date}}</span>
                            <div class="events hidden-mobile">
                                <div class="event" [style.background-color]="event.data.color"
                                    *ngFor="let event of events" [title]="event.data.name + event.data.description">
                                    <div class="event-overlay">
                                        <h3 class="event-overlay__title">
                                            {{event.data.name}}
                                        </h3>
                                        <p class="event-overlay__description">
                                            {{event.data.description}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </ng-template>
                </ngx-simple-calendar>
            </div>
        </div>

        <ul class="calendar-events">
            <ng-container *ngFor="let c of calendario; let i = index;">
                <li class="calendar-events__item">
                    <div class="calendar-events__day">{{helper.moment(c.data).format('DD')}}</div>
                    <strong>{{c.nome}}</strong>&nbsp;
                    <span>- {{c.descricao}}</span>
                    <a [href]="c.link" target="_blank" *ngIf="c.link">
                        <i class="fas fa-link"></i>
                    </a>
                </li>
            </ng-container>
        </ul>
    </div>

</section>

<app-footer></app-footer>