import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  apiUrl: string = 'https://api.rosaticontabilidade.com.br';

  constructor() { }
}
