import { Component, OnInit, Inject, ViewChild, AfterViewInit, ElementRef, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HelperService } from 'src/app/services/helper.service';
import { NgxCalendarComponent } from 'ss-ngx-calendar';
import { Moment } from 'moment';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { Agenda } from 'src/app/models/agenda.model';
import { NgxSimpleCalendarComponent } from 'ngx-simple-calendar';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss']
})
export class AgendaComponent implements OnInit, AfterViewInit {

  @HostListener('window:resize', [])
  handleScroll(): void {
    const el = window.getComputedStyle(this.document.querySelector('.round-date')).height;
    const elementHeight = parseInt(el.substring(0, el.length - 2));
    this.simpleCalendarHeight = (elementHeight * 5) + 234;
  }

  @ViewChild('simpleCalendar') simpleCalendarEl: NgxSimpleCalendarComponent;

  buscarSubscription: Subscription;
  calendarEvents = [];

  calendario: any[] = [];

  totalCalendarItems: number;
  openedCalendarEvents: boolean = false;
  openFilter: boolean = false;

  agendas: Agenda[] = [];

  buscarFiltrosSubscription: Subscription;

  simpleCalendarHeight: number = 720;

  filtros: any[] = [];

  months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ]

  days = [
    'Domingo',
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
  ]

  calendarOptions = {
    outline: false,
    evenDayDimensions: true
  };

  loading: boolean = false;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    public helper: HelperService,
    public data: DataService
  ) { }

  ngOnInit(): void {
    this.buscarFiltros();
  }

  ngOnDestroy() {
    this.buscarFiltrosSubscription.unsubscribe();
    this.buscarSubscription.unsubscribe()
  }

  ngAfterViewInit() {
    this.buscarAgendas();
    console.log(this.simpleCalendarEl.days);
  }

  buscarFiltros() {
    this.buscarFiltrosSubscription = this.data.buscarFiltros(-99, -99)
      .subscribe((res: any) => {
        console.log(res);
        this.filtros = res.map(f => {
          f.checked = false;
          return f;
        });
      })
  }

  generateDate(data) {
    console.log(data);
    const year = parseInt(this.helper.moment(data).format('YYYY'));
    const month = parseInt(this.helper.moment(data).format('MM'));
    const day = parseInt(this.helper.moment(data).format('DD'));
    const hours = 1;
    return new Date(year, month - 1, day, hours);
  }

  buscarAgendas() {

    this.loading = true;

    if (this.buscarSubscription && !this.buscarSubscription.closed) {
      this.buscarSubscription.unsubscribe();
    }

    const currentDate = new Date(this.simpleCalendarEl.currentYear, this.simpleCalendarEl.currentMonth, new Date().getDay()).toISOString();

    this.buscarSubscription = this.data.buscarAgendas(-99, -99, currentDate, this.getCalendarFilteredItens())
      .subscribe((res: Agenda[]) => {
        this.calendario = res;
        this.calendarEvents = res.map(item => {
          const data = {
            startDateTime: this.generateDate(item.data),
            data: {
              name: item.nome,
              description: item.descricao,
              color: '#27a750',
            }
          }
          return data;
        })
        const el = window.getComputedStyle(this.document.querySelector('.round-date')).height;
        const els = this.document.querySelectorAll('.round-date');
        console.log(els);
        const elementHeight = parseInt(el.substring(0, el.length - 2));
        this.simpleCalendarHeight = (elementHeight * (els.length / 7)) + 234;
        console.log(this.simpleCalendarHeight);
        this.loading = false;
      }, e => this.loading = false);
  }

  openCalendarEvents() {
    this.openedCalendarEvents = !this.openedCalendarEvents;
  }

  calendarFilterChange(e, i) {
    this.filtros[i].checked = e;
    this.buscarAgendas();
  }

  getCalendarFilteredItens() {
    let filters = [];
    this.filtros.forEach(f => {
      if (f.checked) {
        filters.push(f.id);
      }
    });
    if (filters.length) {
      return filters.join(',').toString();
    } else {
      return '%';
    }
  }

  cleanFilters() {
    this.filtros = this.filtros.map(f => {
      f.checked = false;
      return f;
    });
    this.buscarAgendas();
  }

  nextMonth() {
    this.simpleCalendarEl.setNextMonth();
    this.buscarAgendas();
  }

  previousMonth() {
    this.simpleCalendarEl.setPreviousMonth();
    this.buscarAgendas();
  }

}
