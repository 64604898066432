import { Component, OnInit, HostListener, Input } from '@angular/core';
import { CadastroGeral } from 'src/app/models/cadastro-geral.model';
import { ContatoGeral } from 'src/app/models/contato-geral.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input('cadastroGeral') cadastroGeral: CadastroGeral;
  @Input('contatoGeral') contatoGeral: ContatoGeral;

  openMenu: boolean = false;
  openTopMenu: boolean = false;
  active: boolean = false;

  @HostListener('window:scroll', [])
  handleScroll(): void {
    const windowScroll = window.pageYOffset;
    if (windowScroll >= 50) {
      this.active = true;
    } else {
      this.active = false;
    }
  }

  constructor() { }

  ngOnInit() {
  }

}
