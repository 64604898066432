import { Component, OnInit } from '@angular/core';
import { Mensagem } from 'src/app/models/mensagem.model';
import { NgForm } from '@angular/forms';
import { LoadingService } from 'src/app/services/loading.service';
import { AlertService } from 'src/app/services/alert.service';
import { HelperService } from 'src/app/services/helper.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.scss']
})
export class ContatoComponent implements OnInit {

  mensagem: Mensagem = new Mensagem();

  constructor(
    public loadingService: LoadingService,
    public alertService: AlertService,
    public helper: HelperService,
    public _data: DataService
  ) { 
  }

  ngOnInit(): void {
  }

  submit(f: NgForm) {
    console.log(f.invalid);
    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      return;
    }

    this.loadingService.present();

    this._data.inserirContatoMensagem(this.mensagem)
      .subscribe((res: any) => {
        this.alertService.present('Mensagem enviada', 'Em breve entraremos em contato.');
        this.mensagem.telefone = '';
        f.reset();
        this.loadingService.dismiss();
      }, e => {
        this.alertService.present('Não foi possível enviar a mensagem!', 'Desculpe, mas não foi possível enviar a mensagem. Tente novamente outra hora.');
        this.loadingService.dismiss();
      })
  }

}
