import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Subscription } from 'rxjs';
import { Noticia } from 'src/app/models/noticia.model';
import { HelperService } from 'src/app/services/helper.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  tagSelected: string = '%';
  tags: any[] = [];
  noticias: Noticia[] = [];

  buscarTagsSubscription: Subscription;
  buscarNoticiasSubscription: Subscription;
  openFilter: boolean = false;

  page: number = 1;
  noMore: boolean = false;

  constructor(
    public _data: DataService,
    public helper: HelperService,
    public loadingService: LoadingService
  ) { }

  ngOnInit(): void {
    this.buscarTags();
    this.buscarNoticias();
  }

  ngOnDestroy() {
    this.buscarTagsSubscription.unsubscribe();
    this.buscarNoticiasSubscription.unsubscribe();
  }

  buscarNoticias() {
    this.loadingService.present();
    this.buscarNoticiasSubscription = this._data.buscarNoticias(this.page, 8, this.tagSelected)
      .subscribe((res: Noticia[]) => {
        if (res.length < 8) {
          this.noMore = true;
        }
        this.noticias = this.page == 1 ? res : this.noticias.concat(res);
        this.loadingService.dismiss();
      }, e => this.loadingService.dismiss());
  }

  buscarTags() {
    this.buscarTagsSubscription = this._data.buscarTags()
      .subscribe((res: any[]) => {
        this.tags = res;
      })
  }

  onTagChange(ev) {
    this.page = 1;
    this.buscarNoticias();
  }

  loadMore() {
    this.page++;
    this.buscarNoticias();
  }

}
