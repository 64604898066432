import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { Noticia } from 'src/app/models/noticia.model';
import { Subscription } from 'rxjs';
import { SafeHtml, DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { HelperService } from 'src/app/services/helper.service';
import { CadastroGeral } from 'src/app/models/cadastro-geral.model';

@Component({
  selector: 'app-blog-interno',
  templateUrl: './blog-interno.component.html',
  styleUrls: ['./blog-interno.component.scss']
})
export class BlogInternoComponent implements OnInit {

  shareUrl: string = 'https://rosaticontabilidade.com.br';

  cadastroGeral: CadastroGeral = new CadastroGeral();
  noticia: Noticia = new Noticia();
  buscarNoticiaSubscription: Subscription;

  html: SafeHtml;



  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public _data: DataService,
    public sanitizer: DomSanitizer,
    public helper: HelperService,
    public meta: Meta,
    public title: Title
  ) {
  }

  ngOnInit(): void {
    this.shareUrl += this.router.url;
    this.helper.setCanonicalURL();
    this.buscarCadastroGeral();
    this.route.params.subscribe(param => this.buscarNoticia(param.slug));
  }

  ngOnDestroy() {
    this.updateTags(this.cadastroGeral, false);
    this.buscarNoticiaSubscription.unsubscribe();
  }

  buscarCadastroGeral() {
    this._data.buscarCadastroGeral()
      .subscribe((res: CadastroGeral) => this.cadastroGeral = res)
  }

  buscarNoticia(slug: string) {
    this.buscarNoticiaSubscription = this._data.buscarNoticia(slug)
      .subscribe((res: Noticia) => {
        this.noticia = res;
        this.html = this.sanitizer.bypassSecurityTrustHtml(res.descricao);
        this.updateTags(res, true);
      })
  }

  updateTags(res: any, isArticle: boolean) {
    this.title.setTitle(res.titulo);
    this.meta.updateTag({ name: 'title', content: res.titulo });
    this.meta.updateTag({ name: 'subject', content: res.titulo });
    this.meta.updateTag({ name: 'description', content: res.descricaoSimples });
    this.meta.updateTag({ name: 'url', content: this.shareUrl });
    this.meta.updateTag({ name: 'image', content: res.imagem });
    this.meta.updateTag({ property: 'og:title', content: res.titulo });
    this.meta.updateTag({ property: 'og:description', content: res.descricaoSimples });
    this.meta.updateTag({ property: 'og:url', content: this.shareUrl });
    this.meta.updateTag({ property: 'og:image', content: res.imagem });
    this.meta.updateTag({ property: 'og:image:secure_url', content: res.imagem });
    this.meta.updateTag({ property: 'og:image:alt', content: res.titulo });
    this.meta.updateTag({ name: 'twitter:title', content: res.titulo });
    this.meta.updateTag({ name: 'twitter:description', content: res.descricaoSimples });
    this.meta.updateTag({ name: 'twitter:url', content: this.shareUrl });
    this.meta.updateTag({ name: 'twitter:image', content: res.imagem });
    this.meta.updateTag({ name: 'twitter:image:src', content: res.imagem });
    this.meta.updateTag({ name: 'twitter:creator', content: res.autor });

    if (isArticle) {
      this.meta.addTags([
        { property: "article:tag", content: "alt tag" },
        { property: "article:tag", content: "canonical tag" },
        { property: "article:tag", content: "header tag" },
        { property: "article:tag", content: "html" },
        { property: "article:tag", content: "html tags" },
        { property: "article:tag", content: "meta description" },
        { property: "article:tag", content: "meta tags" },
        { property: "article:tag", content: "open graph" },
        { property: "article:tag", content: "robots meta tag" },
        { property: "article:tag", content: "seo" },
        { property: "article:tag", content: "title tag" },
        { property: "article:tag", content: "twitter card" },
        { property: "article:tag", content: "twitter card" },
        { property: "article:section", content: res.titulo },
        { property: "article:published_time", content: res.data.toString() },
        { property: "article:modified_time", content: res.data.toString() },
      ])
    } else {
      this.meta.removeTag("property='article:tag'");
      this.meta.removeTag("property='article:section'");
      this.meta.removeTag("property='article:published_time'");
      this.meta.removeTag("property='article:modified_time'");
    }
  }
}
