import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { ContatoGeral } from '../models/contato-geral.model';
import { CadastroGeral } from '../models/cadastro-geral.model';
import { Noticia } from '../models/noticia.model';
import { Sobre } from '../models/sobre.model';
import { Mensagem } from '../models/mensagem.model';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    public api: ApiService
  ) { }

  buscarContatoGeral(): Observable<ContatoGeral> {
    return this.api.get('/site/contatoGeral/buscar');
  }

  buscarCadastroGeral(): Observable<CadastroGeral> {
    return this.api.get('/site/cadastroGeral/buscar');
  }

  buscarBanners(numeroPagina: number, registrosPorPagina: number): Observable<any> {
    return this.api.get(`/site/banner/buscar?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}`);
  }

  buscarNoticias(numeroPagina: number, registrosPorPagina: number, tag: string): Observable<any> {
    return this.api.get(`/site/noticia/buscar?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}&tag=${tag}`);
  }

  buscarNoticiasRecomendadas(numeroPagina: number, registrosPorPagina: number, idNoticia: number, tag: string) {
    return this.api.get(`/site/noticia/buscarUltimas?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}&idNoticia=${idNoticia}&tag=${tag}`);
  }

  buscarNoticia(slug: string): Observable<Noticia> {
    return this.api.get(`/site/noticia/buscar?slug=${slug}`);
  }

  buscarTags() {
    return this.api.get(`/site/noticia/tag`);
  }

  buscarServicos(numeroPagina: number, registrosPorPagina: number) {
    return this.api.get(`/site/servico/buscar?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}`);
  }

  buscarServico(slug: string) {
    return this.api.get(`/site/servico/buscar?slug=${slug}`);
  }

  buscarFornecedores(numeroPagina: number, registrosPorPagina: number) {
    return this.api.get(`/site/fornecedor/buscar?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}`);
  }

  buscarClientes(numeroPagina: number, registrosPorPagina: number) {
    return this.api.get(`/site/cliente/buscar?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}`);
  }

  buscarDepoimentos(numeroPagina: number, registrosPorPagina: number) {
    return this.api.get(`/site/depoimento/buscar?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}`);
  }

  buscarDiferenciais(numeroPagina: number, registrosPorPagina: number) {
    return this.api.get(`/site/diferencial/buscar?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}`);
  }

  buscarSobre(): Observable<Sobre> {
    return this.api.get(`/site/sobre/buscar`);
  }

  buscarFiltros(numeroPagina: number, registrosPorPagina: number): Observable<Sobre> {
    return this.api.get(`/site/filtroAgenda/buscar?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}`);
  }

  buscarAgendas(numeroPagina: number, registrosPorPagina: number, data: string, idFiltroAgenda: any = '%'): Observable<any> {
    return this.api.get(`/site/agenda/buscar?numeroPagina=${numeroPagina}&registrosPorPagina=${registrosPorPagina}&data=${data}&idFiltroAgenda=${idFiltroAgenda}`);
  }

  inserirContatoMensagem(mensagem: Mensagem): Observable<any> {
    return this.api.post(`/site/contatoMensagem/inserir`, mensagem);
  }


}
