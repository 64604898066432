<section class="servicos">
    <div class="servicos-banner">
        <h1 class="servicos-banner__title">
            Conte com o Rosati para fazer a sua empresa se desenvolver.
        </h1>
        <img src="assets/banner-servicos.jpg" alt="Serviços">
    </div>
    <div class="container">
        <div class="servicos-content">
            <div class="title">
                <h2>Escolha um serviço</h2>
            </div>
            <div class="services-list">
                <a class="services-item" *ngFor="let s of servicos;" [routerLink]="['/servicos', s.slug]"
                    routerLinkActive="active">
                    <div class="services-item-header">
                        <div class="services-item-icon">
                            <img [src]="s.imagem || 'assets/image-placeholder.jpg'" [alt]="s.nome">
                        </div>
                        <h3>{{s.nome}}</h3>
                    </div>
                    <div class="services-item-content">
                        <p>
                            {{s.descricaoSimples | slice:0:86}}{{s.descricaoSimples.length >= 86 ? '...' : ''}}
                        </p>
                    </div>
                </a>
            </div>
        </div>
    </div>

    <div class="big-service" #target>
        <div class="big-service-background" [style.backgroundImage]="'url(' + servico.background + ')'"
            *ngIf="servico.slug"></div>
        <div class="big-service-box" *ngIf="servico.slug">
            <div class="big-service__image">
                <img [src]="servico.imagem || 'assets/image-placeholder.jpg'" [alt]="servico.nome">
            </div>
            <h2 class="big-service__title">
                {{servico.nome}}
            </h2>
            <h3>{{servico.descricaoSimples}}</h3>
            <div [innerHtml]="html" class="summernote"></div>
        </div>
    </div>
</section>

<app-footer></app-footer>