<div class="sobre-banner">
    <h1 class="sobre-banner__title">
        Conheça um pouco mais sobre a história da Rosati
    </h1>
    <img [src]="sobre.imagem" alt="">
</div>

<section class="sobre">
    <div class="container">
        <div class="sobre-description post-content" [innerHtml]="sobre.descricao"></div>
        <div class="sobre-wrapper">
            <div class="sobre-item">
                <h3 class="sobre-item__title">
                    Visão
                </h3>
                <p [innerHtml]="sobre.visao"></p>
            </div>
            <div class="sobre-item">
                <h3 class="sobre-item__title">
                    Valores
                </h3>
                <p [innerHtml]="sobre.valores"></p>
            </div>
            <div class="sobre-item">
                <h3 class="sobre-item__title">
                    Missão
                </h3>
                <p [innerHtml]="sobre.missao"></p>
            </div>
        </div>
    </div>
</section>

<section class="depoimentos">
    <div class="container">
        <div class="title">
            <h2>Depoimentos</h2>
        </div>
        <ngu-carousel #carousel [inputs]="carouselDepoimentos" [dataSource]="depoimentos" (onMove)="onmoveFn($event)"
            class="depoimentos-carousel">

            <ngu-item NguCarouselItem class="depoimentos-carousel-item" *nguCarouselDef="let item; let i = index">
                <div class="depoimentos-item">
                    <div class="depoimentos-item__header">
                        <div class="depoimentos-item__avatar">
                            <img [src]="item.imagem || 'assets/image-placeholder.jpg'" [alt]="item.nome">
                        </div>
                        <h3 class="depoimentos-item__title">
                            {{item.nome}}
                            <small>{{item.cargo}} - {{item.empresa}}</small>
                        </h3>
                    </div>
                    <div class="depoimentos-item__content">
                        <p>
                            "{{item.descricao}}"
                        </p>
                    </div>
                </div>
            </ngu-item>

            <ul class="depoimentos-carousel-dots" NguCarouselPoint>
                <li *ngFor="let point of carousel.pointNumbers; let i = index"
                    [class.active]="i == carousel.activePoint" (click)="carousel.moveTo(i)"
                    class="depoimentos-carousel-dots__item">
                </li>
            </ul>
        </ngu-carousel>
    </div>
</section>

<section class="clients">
    <div class="container">
        <div class="title">
            <h2>Nossos clientes</h2>
        </div>
        <div class="clients-list">
            <a class="clients-item" target="_blank" *ngFor="let c of clientes;" [href]="c.link">
                <img [src]="c.imagem || 'assets/image-placeholder.jpg'" [alt]="c.nome">
            </a>
        </div>
    </div>
</section>

<app-footer></app-footer>