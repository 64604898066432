import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-header-interno',
  templateUrl: './header-interno.component.html',
  styleUrls: ['./header-interno.component.scss']
})
export class HeaderInternoComponent implements OnInit {

  @Input('title') title: string;

  constructor() { }

  ngOnInit(): void {
  }

}
