import { NgModule } from '@angular/core';
// imports
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { NguCarouselModule } from '@ngu/carousel';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPhoneMaskBrModule } from 'ngx-phone-mask-br';
import { NgxCalendarModule } from "ss-ngx-calendar";
import { NgxSimpleCalendarModule } from 'ngx-simple-calendar';
// components
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
// pages
import { HomeComponent } from './pages/home/home.component';
import { ContatoComponent } from './pages/contato/contato.component';
import { HeaderInternoComponent } from './components/header-interno/header-interno.component';
import { BlogComponent } from './pages/blog/blog.component';
import { BlogInternoComponent } from './pages/blog-interno/blog-interno.component';
import { ServicosComponent } from './pages/servicos/servicos.component';
import { SobreComponent } from './pages/sobre/sobre.component';
import { AgendaComponent } from './pages/agenda/agenda.component';
import { AlertComponent } from './components/alert/alert.component';
import { LoadingComponent } from './components/loading/loading.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    ContatoComponent,
    HeaderInternoComponent,
    BlogComponent,
    BlogInternoComponent,
    ServicosComponent,
    SobreComponent,
    AgendaComponent,
    AlertComponent,
    LoadingComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    NguCarouselModule,
    HttpClientModule,
    FormsModule,
    NgxPhoneMaskBrModule,
    NgxMaskModule.forRoot(),
    NgxCalendarModule,
    NgxSimpleCalendarModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
