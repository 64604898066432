import { Component, OnInit, ViewChild } from '@angular/core';
import { NguCarouselConfig, NguCarousel } from '@ngu/carousel';
import { Subscription, forkJoin } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { Depoimento } from 'src/app/models/depoimento.model';
import { Cliente } from 'src/app/models/cliente.model';
import { Sobre } from 'src/app/models/sobre.model';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-sobre',
  templateUrl: './sobre.component.html',
  styleUrls: ['./sobre.component.scss']
})
export class SobreComponent implements OnInit {

  @ViewChild('banner') carousel: NguCarousel<any>;

  carouselDepoimentos: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 2, lg: 3, all: 0 },
    slide: 1,
    speed: 500,
    interval: {
      timing: 3000,
      initialDelay: 1000
    },
    point: {
      visible: true
    },
    load: 1,
    loop: true,
    touch: true,
    easing: 'cubic-bezier(0, 0, 0.2, 1)'
  };

  sobre: Sobre = new Sobre();
  depoimentos: Depoimento[] = [];
  clientes: Cliente[] = [];

  html: SafeHtml;

  forkSubscription: Subscription;

  constructor(
    public _data: DataService,
    public sanitize: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.fork();
  }

  ngOnDestroy() {
    this.forkSubscription.unsubscribe();
  }

  onmoveFn(ev) {
  }

  fork() {

    let buscarSobre = this._data.buscarSobre();
    let buscarDepoimentos = this._data.buscarDepoimentos(-99, -99);
    let buscarClientes = this._data.buscarClientes(-99, -99);

    this.forkSubscription = forkJoin([buscarSobre, buscarDepoimentos, buscarClientes])
      .subscribe((res: any) => {
        this.sobre = res[0];
        this.html = this.sanitize.bypassSecurityTrustHtml(res[0].descricao);
        this.depoimentos = res[1];
        this.clientes = res[2];
      })
  }

}
