import { Component } from '@angular/core';
import { DataService } from './services/data.service';
import { Meta, Title } from '@angular/platform-browser';
import { HelperService } from './services/helper.service';
import { ContatoGeral } from './models/contato-geral.model';
import { CadastroGeral } from './models/cadastro-geral.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  cadastroGeral: CadastroGeral = new CadastroGeral();
  contatoGeral: ContatoGeral = new ContatoGeral();

  constructor(
    public data: DataService,
    public meta: Meta,
    public title: Title,
    public helper: HelperService
  ) {
    this.buscarContatoGeral();
    this.buscarCadastroGeral();
  }

  buscarCadastroGeral() {
    this.data.buscarCadastroGeral()
      .subscribe((res: CadastroGeral) => {
        this.cadastroGeral = res;
        this.title.setTitle(res.titulo);
        this.meta.addTags([
          { name: 'date', content: this.helper.moment().format('YYYY-MM-DD'), scheme: 'YYYY-MM-DD' },
        ])
      })
  }

  buscarContatoGeral() {
    this.data.buscarContatoGeral()
      .subscribe((res: ContatoGeral) => {
        this.contatoGeral = res;
      })
  }
}
