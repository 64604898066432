<div class="blog-banner">
    <h1 class="blog-banner__title">
        Acompanhe nossas últimas notícias
    </h1>
    <img src="assets/banner-blog.jpg" alt="Banner blog">
</div>

<section class="blog">
    <div class="container">
        <!-- <div class="blog-featured">
            <div class="title">
                <h2>Destaque</h2>
            </div>
            <div class="blog-list">
                <a class="blog-item blog-item--featured" [routerLink]="['/blog', noticias[0].slug]">
                    <div class="blog-item__image">
                        <img [src]="noticias[0].imagemPequena || 'assets/image-placeholder.jpg'"
                            [alt]="noticias[0].titulo">
                    </div>
                    <div class="blog-item__content">
                        <h3 class="blog-item__title">{{noticias[0].titulo}}</h3>
                        <div class="blog-item__info">
                            <i class="fas fa-calendar-alt"></i>
                            <span>
                                {{helper.moment(noticias[0].data).format('DD')}} de
                                {{helper.moment(noticias[0].data).format('MMM')}},
                                {{helper.moment(noticias[0].data).format('YYYY')}}
                            </span>
                        </div>
                        <div class="blog-item__info">
                            <i class="fas fa-user"></i>
                            <span>{{noticias[0].autor}}</span>
                        </div>
                        <p class="blog-item__description">
                            {{noticias[0].descricaoSimples}}
                        </p>
                        <div class="blog-item__link">
                            <span>acessar</span>
                            <i class="fas fa-long-arrow-alt-right"></i>
                        </div>
                    </div>
                </a>
            </div>
        </div>

        <div class="divisor"></div> -->

        <div class="title">
            <h2>Últimas notícias</h2>
        </div>

        <div class="blog-filter">
            <div class="filters">
                <h3 class="filters__title">Filtros:&nbsp;&nbsp;</h3>
                <div class="filters-list">
                    <div class="filters-item">
                        <input type="radio" [(ngModel)]="tagSelected" (ngModelChange)="onTagChange($event)" id="todos"
                            [value]="'%'" [name]="'todos'">
                        <label for="todos">Todos</label>
                    </div>
                    <div class="filters-item" *ngFor="let t of tags; let i = index;">
                        <input type="radio" [(ngModel)]="tagSelected" (ngModelChange)="onTagChange($event)"
                            [id]="'tag'+i" [value]="t" [name]="t">
                        <label [for]="'tag'+i">{{t}}</label>
                    </div>
                </div>
                <button class="btn btn-default btn-small btn-block hidden-desktop"
                    (click)="openFilter = !openFilter">mostrar
                    {{openFilter ? 'menos' : 'mais'}}</button>
            </div>
        </div>

        <div class="blog-list">
            <a class="blog-item" *ngFor="let n of noticias; let i = index;" [routerLink]="['/blog', n.slug]">
                <div class="blog-item__image">
                    <img [src]="n.imagemPequena || 'assets/image-placeholder.jpg'" [alt]="n.titulo">
                </div>
                <div class="blog-item__content">
                    <h3 class="blog-item__title">{{n.titulo}}</h3>
                    <div class="blog-item__info">
                        <i class="fas fa-calendar-alt"></i>
                        <span>{{helper.moment(n.data).format('DD')}} de {{helper.moment(n.data).format('MMM')}},
                            {{helper.moment(n.data).format('YYYY')}}</span>
                    </div>
                    <div class="blog-item__info">
                        <i class="fas fa-user"></i>
                        <span>{{n.autor}}</span>
                    </div>
                    <p class="blog-item__description">
                        {{n.descricaoSimples}}
                    </p>
                    <div class="blog-item__link">
                        <span>acessar</span>
                        <i class="fas fa-long-arrow-alt-right"></i>
                    </div>
                </div>
            </a>
        </div>

        <div class="blog-list-button" *ngIf="!noMore">
            <button class="btn btn-default" (click)="loadMore()">
                CARREGAR MAIS
            </button>
        </div>

    </div>
</section>

<app-footer></app-footer>