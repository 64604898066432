<app-header-interno [title]="'Fale conosco'"></app-header-interno>

<section class="contato">
    <div class="container">
        <div class="contato-wrapper">
            <div class="left">
                <h2 class="contato-title">
                    Vamos conversar?
                </h2>
                <h3>
                    Envie sua solicitação e veja como podemos atender
                    a sua empresa da melhor forma.<br />Se preferir, ligue para <a href="tel:555430255599">(54)
                        3025-5599</a>
                </h3>
                <form class="form" (ngSubmit)="submit(form)" #form="ngForm">
                    <div class="form-control">
                        <label for="nome">Nome</label>
                        <input type="text" id="nome" name="nome" autocomplete="name" placeholder="Digite aqui seu nome"
                            [(ngModel)]="mensagem.nome" #nome="ngModel" required>
                        <small class="form-control-error" *ngIf="nome.invalid && (nome.touched || nome.dirty)">*Preencha
                            este campo.</small>
                    </div>
                    <div class="form-control half">
                        <label for="email">E-mail</label>
                        <input type="email" id="email" name="e-mail" autocomplete="email" [(ngModel)]="mensagem.email"
                            placeholder="Digite aqui seu e-mail" #email="ngModel" required>
                        <small class="form-control-error"
                            *ngIf="email.invalid && (email.touched || email.dirty)">*Preencha
                            este campo.</small>
                    </div>
                    <div class="form-control half">
                        <label for="telefone">Telefone</label>
                        <input type="tel" id="telefone" name="telefone" autocomplete="tel"
                            placeholder="Digite aqui seu telefone" ngxPhoneMaskBr [(ngModel)]="mensagem.telefone"
                            #telefone="ngModel" required>
                        <small class="form-control-error"
                            *ngIf="telefone.invalid && (telefone.touched || telefone.dirty)">*Preencha
                            este campo.</small>
                    </div>
                    <div class="form-control">
                        <label for="assunto">Assunto</label>
                        <select name="assunto" id="assunto" [(ngModel)]="mensagem.assunto">
                            <option value="">Selecionar</option>
                            <option value="Sugestão">Sugestão</option>
                            <option value="Reclamação">Reclamação</option>
                            <option value="Elogio">Elogio</option>
                            <option value="Orçamento">Orçamento</option>
                            <option value="Dúvida">Dúvida</option>
                        </select>
                    </div>
                    <div class="form-control">
                        <label for="mensagem">Mensagem</label>
                        <textarea name="mensagem" id="mensagem" cols="30" rows="6"
                            placeholder="Digite aqui sua mensagem" #msg="ngModel" [(ngModel)]="mensagem.mensagem"
                            required></textarea>
                        <small class="form-control-error" *ngIf="msg.invalid && (msg.touched || msg.dirty)">*Preencha
                            este campo.</small>
                    </div>
                    <button class="btn btn-default btn-large" type="submit">
                        ENVIAR
                    </button>
                </form>
            </div>
            <div class="right">
                <div class="contato-image">
                    <img src="assets/banner-contato.jpeg" alt="Contato">
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>