<section class="blog-interno">
    <div class="blog-interno-header">
        <div class="blog-interno-wrapper">
            <div class="left">
                <div class="tags">
                    <span class="tags-item">{{noticia.tag}}</span>
                </div>
                <h1 class="blog-interno__title">
                    {{noticia.titulo}}
                </h1>
                <h2 class="blog-interno__description">
                    {{noticia.descricaoSimples}}
                </h2>
                <div class="blog-interno-info">
                    <i class="fas fa-user"></i>
                    <span>{{noticia.autor}}</span>
                </div>
                <div class="blog-interno-info">
                    <i class="fas fa-calendar-alt"></i>
                    <span>
                        {{helper.moment(noticia.data).format('DD')}} de {{helper.moment(noticia.data).format('MMM')}},
                        {{helper.moment(noticia.data).format('YYYY')}}
                    </span>
                </div>
            </div>
            <div class="right">
                <img [src]="noticia.imagemGrande || 'assets/image-placeholder.jpg'" [alt]="noticia.titulo">
            </div>

        </div>
    </div>
    <div class="blog-interno-content">
        <div class="container">
            <div class="post">
                <ul class="social-sharing">
                    <li>
                        <a [href]="'http://www.facebook.com/sharer.php?u=' + shareUrl" class="social-item__link"
                            title="Facebook" target="_blank">
                            <img src="assets/icons/facebook.svg" alt="Facebook">
                        </a>
                    </li>
                    <li>
                        <a [href]="'https://twitter.com/intent/tweet?url=' + shareUrl" class="social-item__link"
                            title="Twitter" target="_blank">
                            <img src="assets/icons/twitter.svg" alt="Facebook">
                        </a>
                    </li>
                    <li>
                        <a [href]="'https://www.linkedin.com/shareArticle/?mini=true&url=' + shareUrl" title="Linkedin"
                            target="_blank">
                            <img src="assets/icons/linkedin.svg" alt="Facebook">
                        </a>
                    </li>
                    <li>
                        <a class="social-item__link" title="Whatsapp"
                            [href]="sanitizer.bypassSecurityTrustResourceUrl('whatsapp://send?text=Da%20uma%20olhada%20nessa%20publicação%20da%20Rosati%20Contabilidade!%20' + shareUrl)"
                            target="_blank">
                            <img src="assets/icons/whatsapp.svg" alt="Facebook">
                        </a>
                    </li>
                </ul>
                <div class="post-content summernote" [innerHtml]="html"></div>
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>