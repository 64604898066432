import { Component, OnInit, ViewChild } from '@angular/core';
import { NguCarousel, NguCarouselConfig, NguCarouselStore } from '@ngu/carousel';
import { Subscription, forkJoin } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { Sobre } from 'src/app/models/sobre.model';
import { Noticia } from 'src/app/models/noticia.model';
import { Servico } from 'src/app/models/servico.model';
import { Banner } from 'src/app/models/banner.model';
import { Diferencial } from 'src/app/models/diferencial.model';
import { HelperService } from 'src/app/services/helper.service';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { NgForm } from '@angular/forms';
import { LoadingService } from 'src/app/services/loading.service';
import { AlertService } from 'src/app/services/alert.service';
import { Mensagem } from 'src/app/models/mensagem.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @ViewChild('banner') carousel: NguCarousel<any>;

  sobre: Sobre = new Sobre();
  noticias: Noticia[] = [];
  servicos: Servico[] = [];
  banners: Banner[] = [];
  diferenciais: Diferencial[] = [];
  mensagem: Mensagem = new Mensagem();

  html: SafeHtml;

  forkSubscription: Subscription;

  carouselBanner: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    slide: 1,
    speed: 500,
    interval: {
      timing: 3000,
      initialDelay: 1000
    },
    point: {
      visible: true
    },
    load: 1,
    loop: true,
    touch: false,
    easing: 'cubic-bezier(0, 0, 0.2, 1)'
  };

  constructor(
    public _data: DataService,
    public helper: HelperService,
    public sanitizer: DomSanitizer,
    public loadingService: LoadingService,
    public alertService: AlertService
  ) { }

  ngOnInit() {
    this.fork();
  }

  ngOnDestroy() {
    this.forkSubscription.unsubscribe();
  }

  onmoveFn(data: NguCarouselStore) {
  }

  fork() {

    let buscarBanners = this._data.buscarBanners(-99, -99);
    let buscarSobre = this._data.buscarSobre();
    let buscarNoticias = this._data.buscarNoticias(1, 2, '%');
    let buscarServicos = this._data.buscarServicos(-99, -99);
    let buscarDiferenciais = this._data.buscarDiferenciais(-99, -99);

    this.forkSubscription = forkJoin([buscarBanners, buscarSobre, buscarNoticias, buscarServicos, buscarDiferenciais])
      .subscribe((res: any) => {
        this.banners = res[0];
        this.sobre = res[1];
        this.html = this.sanitizer.bypassSecurityTrustHtml(res[1].descricao);
        this.noticias = res[2];
        this.servicos = res[3];
        this.diferenciais = res[4];
      })
  }

  submit(f: NgForm) {
    console.log(f.invalid);
    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      return;
    }

    this.loadingService.present();

    this._data.inserirContatoMensagem(this.mensagem)
      .subscribe((res: any) => {
        this.alertService.present('Mensagem enviada', 'Em breve entraremos em contato.');
        this.mensagem.telefone = '';
        f.reset();
        this.loadingService.dismiss();
      }, e => {
        this.alertService.present('Não foi possível enviar a mensagem!', 'Desculpe, mas não foi possível enviar a mensagem. Tente novamente outra hora.');
        this.loadingService.dismiss();
      })
  }

}
