<section class="alert" [class.active]="alertService.active">
    <h2 class="alert__title">
        {{alertService.title}}
    </h2>
    <hr>
    <p class="alert__description">
        {{alertService.descricao}}
    </p>
    <button class="btn btn-default" (click)="alertService.dismiss()">FECHAR</button>
</section>
<div class="alert-backdrop" [class.active]="alertService.active" (click)="alertService.dismiss()"></div>