<footer class="footer">
    <div class="container">
        <div class="footer-wrapper">
            <div class="col">
                <div class="logo">
                    <img src="assets/logo.png" alt="Rosati Contabilidade" class="logo-pic">
                </div>
            </div>
            <div class="col hidden-mobile">
                <h3 class="footer-title">
                    Links rápidos
                </h3>
                <ul class="footer-menu">
                    <li class="footer-menu__item">
                        <a routerLink="/" class="footer-menu__link" title="Home">
                            Home
                        </a>
                    </li>
                    <li class="footer-menu__item">
                        <a routerLink="/sobre" class="footer-menu__link" title="Sobre">
                            Empresa
                        </a>
                    </li>
                    <li class="footer-menu__item">
                        <a routerLink="/agenda" class="footer-menu__link" title="Sobre">
                            Agenda Tributária
                        </a>
                    </li>
                    <li class="footer-menu__item">
                        <a routerLink="/blog" class="footer-menu__link" title="Blog">
                            Blog
                        </a>
                    </li>
                    <li class="footer-menu__item">
                        <a routerLink="/contato" class="footer-menu__link" title="Contato">
                            Contato
                        </a>
                    </li>
                </ul>
            </div>
            <div class="col hidden-mobile">
                <h3 class="footer-title">
                    Serviços
                </h3>
                <ul class="footer-menu">
                    <li class="footer-menu__item" *ngFor="let s of servicos;">
                        <a class="footer-menu__link" [routerLink]="['/servicos', s.slug]">
                            {{s.nome}}
                        </a>
                    </li>
                </ul>
            </div>
            <div class="col">
                <h3 class="footer-title">
                    Entre em contato
                </h3>
                <div class="footer-item">
                    <i class="fas fa-map-marker-alt"></i>
                    <a href="https://www.google.com/maps/dir//Rosati+Contabilidade/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x951ebcd3b15eecd9:0xc96f52111771d3de?sa=X&ved=2ahUKEwi1wcTBjMbpAhWGGbkGHT6BCUYQ9RcwC3oECA8QEQ"
                        target="_blank">{{contatoGeral.rua}},
                        {{contatoGeral.numeroEndereco}} - <ng-container *ngIf="contatoGeral.complemento">
                            {{contatoGeral.complemento + '-'}}</ng-container>
                        {{contatoGeral.bairro}}, {{contatoGeral.cidade}} - {{contatoGeral.uf}}</a>
                </div>
                <div class="footer-item">
                    <i class="fas fa-clock"></i>
                    <span>{{contatoGeral.atendimento}}</span>
                </div>
                <div class="footer-item">
                    <i class="fas fa-envelope"></i>
                    <a [href]="'mailto:'+contatoGeral.email">{{contatoGeral.email}}</a>
                </div>
                <div class="footer-item">
                    <i class="fas fa-phone-alt"></i>
                    <a [href]="'tel:'+contatoGeral.telefone" *ngIf="contatoGeral.telefone.length >= 11">{{contatoGeral.telefone | mask: '(00) 0 0000-0000'}}</a>
                    <a [href]="'tel:'+contatoGeral.telefone" *ngIf="contatoGeral.telefone.length < 11">{{contatoGeral.telefone | mask: '(00) 0000-0000'}}</a>
                </div>
            </div>
            <div class="col">
                <h3 class="footer-title">
                    Redes sociais
                </h3>
                <div class="footer-item">
                    <ul class="footer-social">
                        <li class="footer-social__item" *ngIf="contatoGeral.linkFacebook">
                            <a [href]="contatoGeral.linkFacebook" target="_blank" class="footer-social__link">
                                <i class="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li class="footer-social__item" *ngIf="contatoGeral.linkInstagram">
                            <a [href]="contatoGeral.linkInstagram" class="footer-social__link" target="_blank"> 
                                <i class="fab fa-instagram"></i>
                            </a>
                        </li>
                        <li class="footer-social__item" *ngIf="contatoGeral.linkLinkedin">
                            <a [href]="contatoGeral.linkLinkedin" class="footer-social__link" target="_blank">
                                <i class="fab fa-linkedin-in"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-bottom">
            <div class="footer-copyrights">
                Copyright &copy; 2020 Rosati Contabilidade Ltda.
            </div>
            <a href="https://yourway.digital" target="_blank" class="yourway">
                <img src="assets/yourway.png" alt="YourWay Digital" title="YourWay Digital">
            </a>
        </div>
    </div>
</footer>