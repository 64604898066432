<header class="header" [class.active]="active">
    <div class="header-top" [class.active]="openTopMenu">
        <div class="header-top-wrapper">
            <div class="header-top-item">
                <i class="fas fa-map-marker-alt"></i>
                <a href="https://www.google.com/maps/dir//Rosati+Contabilidade/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x951ebcd3b15eecd9:0xc96f52111771d3de?sa=X&ved=2ahUKEwi1wcTBjMbpAhWGGbkGHT6BCUYQ9RcwC3oECA8QEQ"
                    target="_blank">{{contatoGeral.rua}},
                    {{contatoGeral.numeroEndereco}} - <ng-container *ngIf="contatoGeral.complemento">
                        {{contatoGeral.complemento + '-'}}</ng-container>
                    {{contatoGeral.bairro}}</a>
            </div>
            <div class="header-top-item">
                <i class="fas fa-clock"></i>
                <span>{{contatoGeral.atendimento}}</span>
            </div>
            <div class="header-top-item">
                <i class="fas fa-envelope"></i>
                <a [href]="'mailto:'+contatoGeral.email">{{contatoGeral.email}}</a>
            </div>
            <div class="header-top-item">
                <i class="fas fa-phone-alt"></i>
                <a [href]="'tel:'+contatoGeral.telefone">
                    <ng-container *ngIf="contatoGeral.telefone.length < 11">{{contatoGeral.telefone | mask: '(00) 0000-0000'}}</ng-container>
                    <ng-container *ngIf="contatoGeral.telefone.length >= 11">{{contatoGeral.telefone | mask: '(00) 0 0000-0000'}}</ng-container>
                </a>
            </div>
            <div class="header-top-item">
                <ul class="header-social">
                    <li class="header-social__item" *ngIf="contatoGeral.linkFacebook">
                        <a [href]="contatoGeral.linkFacebook" target="_blank" class="header-social__link">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                    </li>
                    <li class="header-social__item" *ngIf="contatoGeral.linkInstagram">
                        <a [href]="contatoGeral.linkInstagram" target="_blank" class="header-social__link">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </li>
                    <li class="header-social__item" *ngIf="contatoGeral.linkLinkedin">
                        <a [href]="contatoGeral.linkLinkedin" target="_blank" class="header-social__link">
                            <i class="fab fa-linkedin-in"></i>
                        </a>
                    </li>
                </ul>


            </div>
        </div>
    </div>
    <button class="header-top-toggle" (click)="openTopMenu = true">
        ver informações
    </button>
    <div class="header-bottom">
        <nav class="header-nav">
            <a class="logo" routerLink="/" title="Rosati Contabilidade">
                <img src="assets/logo.png" alt="Rosati Contabilidade" class="logo-pic">
            </a>
            <button class="header-menu-toggle" (click)="openMenu =  true">
                <i class="fas fa-bars"></i>
            </button>
            <ul class="header-menu" [class.active]="openMenu">
                <li class="header-menu__item">
                    <a routerLink="/" class="header-menu__link" title="Home" (click)="openMenu = false">
                        Home
                    </a>
                </li>
                <li class="header-menu__item">
                    <a routerLink="/sobre" class="header-menu__link" title="Sobre" (click)="openMenu = false">
                        Empresa
                    </a>
                </li>
                <li class="header-menu__item">
                    <a routerLink="/servicos" class="header-menu__link" title="Serviços" (click)="openMenu = false">
                        Serviços
                    </a>
                </li>
                <li class="header-menu__item">
                    <a routerLink="/agenda" class="header-menu__link" title="Agenda" (click)="openMenu = false">
                        Agenda tributária
                    </a>
                </li>
                <li class="header-menu__item">
                    <a routerLink="/blog" class="header-menu__link" title="Blog" (click)="openMenu = false">
                        Blog
                    </a>
                </li>
                <li class="header-menu__item">
                    <a routerLink="/contato" class="header-menu__link" title="Contato" (click)="openMenu = false">
                        Contato
                    </a>
                </li>
                <li class="header-menu__item">
                    <a href="https://rosaticontabilidade.com.br/admin" class="btn btn-default" target="_blank">
                        <span>área do cliente</span>
                        <i class="fas fa-lock"></i>
                    </a>
                </li>
            </ul>
            <div class="header-menu-backdrop" (click)="openMenu = false; openTopMenu = false"
                [class.active]="openMenu || openTopMenu">
            </div>
        </nav>


    </div>
</header>