import { Injectable, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as moment from 'moment/moment';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  moment;
  emailRegex: RegExp;

  constructor(
    @Inject(DOCUMENT) private dom
  ) {

    moment.locale('pt-Br');
    this.moment = moment;
    this.emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  }

  formMarkAllTouched(form: NgForm) {
    (<any>Object).values(form.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        (<any>Object).values(control.controls).forEach(c => this.formMarkAllTouched(c));
      }
    });
  }

  setCanonicalURL(url?: string) {
    const canURL = url == undefined ? this.dom.URL : url;
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', canURL);
  }

}
