<ngu-carousel #banner [inputs]="carouselBanner" [dataSource]="banners" (onMove)="onmoveFn($event)" class="banner">
    <ngu-item NguCarouselItem class="banner-item" *nguCarouselDef="let item; let i = index">
        <img [src]="item.imagem" [alt]="item.titulo" [title]="item.titulo" loading="lazy" width="1920" height="700"
            class="banner-item__image">
        <div class="container">
            <div class="banner-item-wrapper">
                <div class="left">
                    <h1 class="banner-item__title bebas" *ngIf="i == 0">
                        {{item.titulo}}
                    </h1>
                    <h2 class="banner-item__title bebas" *ngIf="i > 0">
                        {{item.titulo}}
                    </h2>
                    <p class="banner-item__description">
                        {{item.descricao}}
                    </p>
                    <a [href]="item.link" class="btn btn-default btn-large" target="_blank">
                        saiba mais
                    </a>
                </div>
            </div>
        </div>
    </ngu-item>

    <ul class="banner-dots" NguCarouselPoint>
        <li *ngFor="let point of banner.pointNumbers; let i = index" [class.active]="i == banner.activePoint"
            (click)="banner.moveTo(i)" class="banner-dots__item">
        </li>
    </ul>
</ngu-carousel>

<main>
    <section class="home-sobre">
        <div class="container">
            <div class="home-sobre-wrapper">
                <div class="left">
                    <div class="title">
                        <h2>Sobre a Rosati</h2>
                    </div>
                    <div class="home-sobre__description summernote" [innerHtml]="html"></div>
                    <a routerLink="/sobre" class="btn btn-default">
                        CONHEÇA NÓS
                    </a>
                </div>
                <img src="assets/banner-sobre.png" alt="Sobre a Rosati" class="home-sobre-image">
            </div>
        </div>
    </section>
    <section class="home-services">
        <div class="container">
            <div class="title">
                <h2>Serviços</h2>
            </div>
            <div class="services-list">
                <a class="services-item" *ngFor="let s of servicos;" [routerLink]="['/servicos', s.slug]">
                    <div class="services-item-header">
                        <div class="services-item-icon">
                            <img [src]="s.imagem || 'assets/image-placeholder.jpg'" [alt]="s.nome">
                        </div>
                        <h3>{{s.nome}}</h3>
                    </div>
                    <div class="services-item-content">
                        <p>
                            {{s.descricaoSimples | slice:0:86}}{{s.descricaoSimples.length >= 86 ? '...' : ''}}
                        </p>
                    </div>
                </a>
            </div>
        </div>
    </section>
    <section class="benefits">
        <div class="container">
            <div class="benefits-wrapper">
                <h2 class="benefits-title">
                    Por que escolher a Rosati?
                </h2>
                <div class="benefits-list">
                    <div class="benefits-item" *ngFor="let d of diferenciais">
                        <i [class]="d.icone"></i>
                        <h3 class="benefits-item-title">
                            {{d.nome}}
                        </h3>
                        <hr>
                        <p class="benefits-item-description">
                            {{d.descricao}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="home-blog">
        <div class="container">
            <div class="title">
                <h2>Últimas notícias</h2>
                <a routerLink="/blog" class="title-link">
                    <span>ver todas as notícias</span>
                    <i class="fas fa-long-arrow-alt-right"></i>
                </a>
            </div>
            <div class="blog-list">
                <a class="blog-item" *ngFor="let n of noticias; let i = index;" [routerLink]="['/blog', n.slug]">
                    <div class="blog-item__image">
                        <img [src]="n.imagemPequena || 'assets/image-placeholder.jpg'" [alt]="n.titulo">
                    </div>
                    <div class="blog-item__content">
                        <h3 class="blog-item__title">{{n.titulo}}</h3>
                        <div class="blog-item__info">
                            <i class="fas fa-calendar-alt"></i>
                            <span>{{helper.moment(n.data).format('DD')}} de {{helper.moment(n.data).format('MMM')}},
                                {{helper.moment(n.data).format('YYYY')}}</span>
                        </div>
                        <div class="blog-item__info">
                            <i class="fas fa-user"></i>
                            <span>{{n.autor}}</span>
                        </div>
                        <p class="blog-item__description">
                            {{n.descricaoSimples | slice:0:86}}{{n.descricaoSimples.length >= 86 ? '...' : ''}}
                        </p>
                        <div class="blog-item__link">
                            <span>acessar</span>
                            <i class="fas fa-long-arrow-alt-right"></i>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </section>

    <section class="contato home-contato">
        <div class="container">
            <div class="contato-wrapper">
                <div class="left">
                    <h2 class="contato-title">
                        Vamos conversar?
                    </h2>
                    <h3>
                        Envie sua solicitação e veja como podemos atender
                        a sua empresa da melhor forma.<br />Se preferir, ligue para <a href="tel:555430255599">(54)
                            3025-5599</a>
                    </h3>
                    <form class="form" (ngSubmit)="submit(form)" #form="ngForm">
                        <div class="form-control">
                            <label for="nome">Nome</label>
                            <input type="text" id="nome" name="nome" autocomplete="name"
                                placeholder="Digite aqui seu nome" [(ngModel)]="mensagem.nome" required>
                        </div>
                        <div class="form-control half">
                            <label for="email">E-mail</label>
                            <input type="email" id="email" name="e-mail" autocomplete="email"
                                [(ngModel)]="mensagem.email" placeholder="Digite aqui seu e-mail" required>
                        </div>
                        <div class="form-control half">
                            <label for="telefone">Telefone</label>
                            <input type="tel" id="telefone" name="telefone" autocomplete="tel"
                                placeholder="Digite aqui seu telefone" ngxPhoneMaskBr [(ngModel)]="mensagem.telefone"
                                required>
                        </div>
                        <div class="form-control">
                            <label for="assunto">Assunto</label>
                            <select name="assunto" id="assunto" [(ngModel)]="mensagem.assunto">
                                <option value="">Selecionar</option>
                                <option value="Sugestão">Sugestão</option>
                                <option value="Reclamação">Reclamação</option>
                                <option value="Elogio">Elogio</option>
                                <option value="Orçamento">Orçamento</option>
                                <option value="Dúvida">Dúvida</option>
                            </select>
                        </div>
                        <div class="form-control">
                            <label for="mensagem">Mensagem</label>
                            <textarea name="mensagem" id="mensagem" cols="30" rows="6"
                                placeholder="Digite aqui sua mensagem" [(ngModel)]="mensagem.mensagem"></textarea>
                        </div>
                        <button class="btn btn-default btn-large" type="submit">
                            ENVIAR
                        </button>
                    </form>
                </div>
                <div class="right">
                    <div class="contato-image">
                        <img src="assets/banner-contato.jpeg" alt="Contato">
                    </div>
                </div>
            </div>
        </div>
    </section>

    <app-footer></app-footer>
</main>