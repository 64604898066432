import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { ContatoGeral } from 'src/app/models/contato-geral.model';
import { Servico } from 'src/app/models/servico.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  contatoGeral: ContatoGeral = new ContatoGeral();
  servicos: Servico[] = [];

  constructor(
    public data: DataService
  ) { }

  ngOnInit() {
    this.buscarContatoGeral();
    this.buscarServicos();
  }

  buscarContatoGeral() {
    this.data.buscarContatoGeral()
      .subscribe((res: ContatoGeral) => {
        this.contatoGeral = res;
      })
  }

  buscarServicos() {
    this.data.buscarServicos(-99, -99).subscribe((res: Servico[]) => {
      this.servicos = res;
    })
  }

}
