import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';
import { Servico } from 'src/app/models/servico.model';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { Route } from '@angular/compiler/src/core';
import { ActivatedRoute, Router } from '@angular/router';
import { element } from 'protractor';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-servicos',
  templateUrl: './servicos.component.html',
  styleUrls: ['./servicos.component.scss']
})
export class ServicosComponent implements OnInit {

  @ViewChild('target') targetElement: ElementRef;
  @ViewChild('banner') carousel: NguCarousel<any>;

  servicos: Servico[] = [];
  servico: Servico = new Servico();
  buscarServicosSubscription: Subscription;

  html: SafeHtml;

  carouselDepartamentos: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 2, lg: 3, all: 0 },
    slide: 1,
    speed: 500,
    interval: {
      timing: 3000,
      initialDelay: 1000
    },
    point: {
      visible: true
    },
    load: 1,
    loop: false,
    touch: false,
    easing: 'cubic-bezier(0, 0, 0.2, 1)'
  };

  departamentos: any[] = [
    { icon: 'assets/icons/law.svg', titulo: 'Fiscal', descricao: 'Precisando saber qual a forma de tributação mais favorável para a sua empresa?' },
    { icon: 'assets/icons/agreement.svg', titulo: 'Societário', descricao: 'Buscando o melhor tipo societário para a gestão do seu negócio?' },
    { icon: 'assets/icons/business-and-finance.svg', titulo: 'Pessoal', descricao: 'Necessitando de diretrizes para uma gestão de RH mais efetiva e organizada?' },
    { icon: 'assets/icons/calculator.svg', titulo: 'Contábil', descricao: 'Querendo mais segurança para realizar o planejamento gerencial?' },
    { icon: 'assets/icons/piggy-bank.svg', titulo: 'Pessoa Física', descricao: 'Buscando esclarecimentos sobre suas responsabilidades fiscais?' },
  ]

  constructor(
    public _data: DataService,
    public route: ActivatedRoute,
    public router: Router,
    public sanitizer: DomSanitizer
  ) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(param => {
      if (param.slug) {
        this.buscarServicos(param.slug);
      } else {
        this.buscarServicos();
      }
    });
  }

  ngOnDestroy() {
    this.buscarServicosSubscription.unsubscribe();
  }

  onmoveFn(e) {
  }

  buscarServicos(slug?: string) {
    this.buscarServicosSubscription = this._data.buscarServicos(-99, -99)
      .subscribe((res: Servico[]) => {
        this.servicos = res;
        if (slug) {
          this.buscarServico(slug);
          this.scroll(this.targetElement.nativeElement);
        }
      })
  }

  buscarServico(slug: string) {
    this._data.buscarServico(slug).subscribe((res: Servico) => {
      this.servico = res;
      this.html = this.sanitizer.bypassSecurityTrustHtml(res.descricaoCompleta)
    });
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: "smooth" });
  }

}
